<template>
  <div class="">
    <img src="@/assets/bg.jpg" class="bg" />
    <v-dialog v-model="dialog" persistent max-width="450px" min-width="260px">
      <v-card class="px-4">
        <v-img src="@/assets/logo.png" width="162"></v-img>
        <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="3"
          v-model="isInvalid"
        >
          {{ statusText }}
        </v-alert>
        <v-card-title>EBDC Login</v-card-title>
        <v-card-text>
          <v-form ref="loginForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <span>Username</span>
                <v-text-field
                  dense
                  v-model="username"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Password</span>
                <v-text-field
                  dense
                  v-model="password"
                  type="password"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
              <v-spacer></v-spacer>
              <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                <v-btn
                  block
                  :disabled="!valid"
                  color="red white--text"
                  @click="validate"
                  :loading="isLoading"
                >
                  Login
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isInvalid: false,
      statusText: "",
      dialog: true,
      valid: true,
      password: "",
      username: "",
      isLoading: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    };
  },
  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {
        this.isLoading = true;
        let auth = {
          username: this.username,
          password: this.password,
        };

        // Login API
        this.axios
          .post("/api/users/authenticate", auth)
          .then((response) => {
            localStorage.setItem("current_user", JSON.stringify(response.data));
            this.$store.dispatch(
              "setCurrentUser",
              response.data,
              response.data.token
            );
            this.axios.defaults.headers.common["Authorization"] =
              response.data.token;
            this.$router.push("/");
          })
          .catch((error) => {
            if (error.response === undefined) {
              this.statusText = "Tidak bisa terhubung ke server.";
            } else {
              this.statusText = error.response.data.message;
            }

            this.isInvalid = true;
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.bg {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
</style>
